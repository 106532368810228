export const countryOptions = [
  // { value: "", label: "Choose country", icon: "" },
  { value: "NG", label: "Nigeria", icon: "/Assets/NG.png" },
  { value: "US", label: "USA", icon: "/Assets/US.png" },
];

export const nigerian = [
  {
    state: "Abia",
    lgas: [
      "Aba North",
      "Aba South",
      "Arochukwu",
      "Bende",
      "Ikawuno",
      "Ikwuano",
      "Isiala-Ngwa North",
      "Isiala-Ngwa South",
      "Isuikwuato",
      "Umu Nneochi",
      "Obi Ngwa",
      "Obioma Ngwa",
      "Ohafia",
      "Ohaozara",
      "Osisioma",
      "Ugwunagbo",
      "Ukwa West",
      "Ukwa East",
      "Umuahia North",
      "Umuahia South",
    ],
  },
  {
    state: "Adamawa",
    lgas: [
      "Demsa",
      "Fufore",
      "Ganye",
      "Girei",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi-North",
      "Mubi-South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
  },
  {
    state: "Akwa Ibom",
    lgas: [
      "Abak",
      "Eastern-Obolo",
      "Eket",
      "Esit-Eket",
      "Essien-Udim",
      "Etim-Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo-Asutan",
      "Ibiono-Ibom",
      "Ika",
      "Ikono",
      "Ikot-Abasi",
      "Ikot-Ekpene",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat-Enin",
      "Nsit-Atai",
      "Nsit-Ibom",
      "Nsit-Ubium",
      "Obot-Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Oruk Anam",
      "Udung-Uko",
      "Ukanafun",
      "Urue-Offong/Oruko",
      "Uruan",
      "Uyo",
    ],
  },
  {
    state: "Anambra",
    lgas: [
      "Aguata",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Awka North",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili-North",
      "Idemili-South",
      "Ihiala",
      "Njikoka",
      "Nnewi-North",
      "Nnewi-South",
      "Ogbaru",
      "Onitsha-North",
      "Onitsha-South",
      "Orumba-North",
      "Orumba-South",
    ],
  },
  {
    state: "Bauchi",
    lgas: [
      "Alkaleri",
      "Bauchi",
      "Bogoro",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas/Gadau",
      "Jama'Are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Tafawa-Balewa",
      "Toro",
      "Warji",
      "Zaki",
    ],
  },
  {
    state: "Benue",
    lgas: [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer-East",
      "Gwer-West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Otukpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
  },
  {
    state: "Borno",
    lgas: [
      "Abadam",
      "Askira-Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Gubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya-Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
  },
  {
    state: "Bayelsa",
    lgas: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern-Ijaw",
      "Yenagoa",
    ],
  },
  {
    state: "Cross River",
    lgas: [
      "Abi",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar-Municipal",
      "Calabar-South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakurr",
      "Yala",
    ],
  },
  {
    state: "Delta",
    lgas: [
      "Aniocha North",
      "Aniocha-North",
      "Aniocha-South",
      "Bomadi",
      "Burutu",
      "Ethiope-East",
      "Ethiope-West",
      "Ika-North-East",
      "Ika-South",
      "Isoko-North",
      "Isoko-South",
      "Ndokwa-East",
      "Ndokwa-West",
      "Okpe",
      "Oshimili-North",
      "Oshimili-South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli-North",
      "Ughelli-South",
      "Ukwuani",
      "Uvwie",
      "Warri South-West",
      "Warri North",
      "Warri South",
    ],
  },
  {
    state: "Ebonyi",
    lgas: [
      "Abakaliki",
      "Afikpo-North",
      "Afikpo South (Edda)",
      "Ebonyi",
      "Ezza-North",
      "Ezza-South",
      "Ikwo",
      "Ishielu",
      "Ivo",
      "Izzi",
      "Ohaukwu",
      "Onicha",
    ],
  },
  {
    state: "Edo",
    lgas: [
      "Akoko Edo",
      "Egor",
      "Esan-Central",
      "Esan-North-East",
      "Esan-South-East",
      "Esan-West",
      "Etsako-Central",
      "Etsako-East",
      "Etsako-West",
      "Igueben",
      "Ikpoba-Okha",
      "Oredo",
      "Orhionmwon",
      "Ovia-North-East",
      "Ovia-South-West",
      "Owan East",
      "Owan-West",
      "Uhunmwonde",
    ],
  },
  {
    state: "Ekiti",
    lgas: [
      "Ado-Ekiti",
      "Efon",
      "Ekiti-East",
      "Ekiti-South-West",
      "Ekiti-West",
      "Emure",
      "Gbonyin",
      "Ido-Osi",
      "Ijero",
      "Ikere",
      "Ikole",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "Ise-Orun",
      "Moba",
      "Oye",
    ],
  },
  {
    state: "Enugu",
    lgas: [
      "Aninri",
      "Awgu",
      "Enugu-East",
      "Enugu-North",
      "Enugu-South",
      "Ezeagu",
      "Igbo-Etiti",
      "Igbo-Eze-North",
      "Igbo-Eze-South",
      "Isi-Uzo",
      "Nkanu-East",
      "Nkanu-West",
      "Nsukka",
      "Oji-River",
      "Udenu",
      "Udi",
      "Uzo-Uwani",
    ],
  },
  {
    state: "Federal Capital Territory",
    lgas: ["Abuja", "Kwali", "Kuje", "Gwagwalada", "Bwari", "Abaji"],
  },
  {
    state: "Gombe",
    lgas: [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Funakaye",
      "Gombe",
      "Kaltungo",
      "Kwami",
      "Nafada",
      "Shongom",
      "Yamaltu/Deba",
    ],
  },
  {
    state: "Imo",
    lgas: [
      "Aboh-Mbaise",
      "Ahiazu-Mbaise",
      "Ehime-Mbano",
      "Ezinihitte",
      "Ideato-North",
      "Ideato-South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala-Mbano",
      "Isu",
      "Mbaitoli",
      "Ngor-Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji-Egbema",
      "Okigwe",
      "Onuimo",
      "Orlu",
      "Orsu",
      "Oru-East",
      "Oru-West",
      "Owerri-Municipal",
      "Owerri-North",
      "Owerri-West",
    ],
  },
  {
    state: "Jigawa",
    lgas: [
      "Auyo",
      "Babura",
      "Biriniwa",
      "Birnin-Kudu",
      "Buji",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin-Hausa",
      "Kaugama",
      "Kazaure",
      "Kiri kasama",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule-Tankarkar",
      "Taura",
      "Yankwashi",
    ],
  },
  {
    state: "Kebbi",
    lgas: [
      "Aleiro",
      "Arewa-Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Birnin-Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko-Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Wasagu/Danko",
      "Yauri",
      "Zuru",
    ],
  },
  {
    state: "Kaduna",
    lgas: [
      "Birnin-Gwari",
      "Chikun",
      "Giwa",
      "Igabi",
      "Ikara",
      "Jaba",
      "Jema'A",
      "Kachia",
      "Kaduna-North",
      "Kaduna-South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon-Gari",
      "Sanga",
      "Soba",
      "Zangon-Kataf",
      "Zaria",
    ],
  },
  {
    state: "Kano",
    lgas: [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin-Kudu",
      "Dawakin-Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garun-Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano-Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nasarawa",
      "Rano",
      "Rimin-Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takai",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun-Wada",
      "Ungogo",
      "Warawa",
      "Wudil",
    ],
  },
  {
    state: "Kogi",
    lgas: [
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela-Odolu",
      "Ijumu",
      "Kabba/Bunu",
      "Kogi",
      "Lokoja",
      "Mopa-Muro",
      "Ofu",
      "Ogori/Magongo",
      "Okehi",
      "Okene",
      "Olamaboro",
      "Omala",
      "Oyi",
      "Yagba-East",
      "Yagba-West",
    ],
  },
  {
    state: "Katsina",
    lgas: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dan-Musa",
      "Dandume",
      "Danja",
      "Daura",
      "Dutsi",
      "Dutsin-Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai-Adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango",
    ],
  },
  {
    state: "Kwara",
    lgas: [
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti (Araromi/Opin)",
      "Ilorin-East",
      "Ilorin-South",
      "Ilorin-West",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke-Ero",
      "Oyun",
      "Pategi",
    ],
  },
  {
    state: "Lagos",
    lgas: [
      "Agege",
      "Ajeromi-Ifelodun",
      "Alimosho",
      "Amuwo-Odofin",
      "Apapa",
      "Badagry",
      "Epe",
      "Eti-Osa",
      "Ibeju-Lekki",
      "Ifako-Ijaiye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos-Island",
      "Lagos-Mainland",
      "Mushin",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere",
      "Yewa-South",
    ],
  },
  {
    state: "Nasarawa",
    lgas: [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Kokona",
      "Lafia",
      "Nasarawa",
      "Nasarawa-Eggon",
      "Obi",
      "Wamba",
      "Toto",
    ],
  },
  {
    state: "Niger",
    lgas: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Moya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi",
    ],
  },
  {
    state: "Ogun",
    lgas: [
      "Abeokuta-North",
      "Abeokuta-South",
      "Ado-Odo/Ota",
      "Ewekoro",
      "Ifo",
      "Ijebu-East",
      "Ijebu-North",
      "Ijebu-North-East",
      "Ijebu-Ode",
      "Ikenne",
      "Imeko-Afon",
      "Ipokia",
      "Obafemi-Owode",
      "Odeda",
      "Odogbolu",
      "Ogun-Waterside",
      "Remo-North",
      "Shagamu",
      "Yewa North",
    ],
  },
  {
    state: "Ondo",
    lgas: [
      "Akoko North-East",
      "Akoko North-West",
      "Akoko South-West",
      "Akoko South-East",
      "Akure-North",
      "Akure-South",
      "Ese-Odo",
      "Idanre",
      "Ifedore",
      "Ilaje",
      "Ile-Oluji-Okeigbo",
      "Irele",
      "Odigbo",
      "Okitipupa",
      "Ondo West",
      "Ondo-East",
      "Ose",
      "Owo",
    ],
  },
  {
    state: "Osun",
    lgas: [
      "Atakumosa West",
      "Atakumosa East",
      "Ayedaade",
      "Ayedire",
      "Boluwaduro",
      "Boripe",
      "Ede South",
      "Ede North",
      "Egbedore",
      "Ejigbo",
      "Ife North",
      "Ife South",
      "Ife-Central",
      "Ife-East",
      "Ifelodun",
      "Ila",
      "Ilesa-East",
      "Ilesa-West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo-Otin",
      "Ola Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo",
    ],
  },
  {
    state: "Oyo",
    lgas: [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan North",
      "Ibadan North-East",
      "Ibadan North-West",
      "Ibadan South-East",
      "Ibadan South-West",
      "Ibarapa-Central",
      "Ibarapa-East",
      "Ibarapa-North",
      "Ido",
      "Ifedayo",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogo-Oluwa",
      "Ogbomosho-North",
      "Ogbomosho-South",
      "Olorunsogo",
      "Oluyole",
      "Ona-Ara",
      "Orelope",
      "Ori-Ire",
      "Oyo-West",
      "Oyo-East",
      "Saki-East",
      "Saki-West",
      "Surulere",
    ],
  },
  {
    state: "Plateau",
    lgas: [
      "Barkin-Ladi",
      "Bassa",
      "Bokkos",
      "Jos-East",
      "Jos-North",
      "Jos-South",
      "Kanam",
      "Kanke",
      "Langtang-North",
      "Langtang-South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase",
    ],
  },
  {
    state: "Rivers",
    lgas: [
      "Abua/Odual",
      "Ahoada-East",
      "Ahoada-West",
      "Akuku Toru",
      "Andoni",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Eleme",
      "Emuoha",
      "Etche",
      "Gokana",
      "Ikwerre",
      "Khana",
      "Obio/Akpor",
      "Ogba-Egbema-Ndoni",
      "Ogba/Egbema/Ndoni",
      "Ogu/Bolo",
      "Okrika",
      "Omuma",
      "Opobo/Nkoro",
      "Oyigbo",
      "Port-Harcourt",
      "Tai",
    ],
  },
  {
    state: "Sokoto",
    lgas: [
      "Binji",
      "Bodinga",
      "Dange-Shuni",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gwadabawa",
      "Illela",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Sokoto-North",
      "Sokoto-South",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo",
    ],
  },
  {
    state: "Taraba",
    lgas: [
      "Ardo-Kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Jalingo",
      "Karim-Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing",
    ],
  },
  {
    state: "Yobe",
    lgas: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmuwa",
      "Yunusari",
      "Yusufari",
    ],
  },
  {
    state: "Zamfara",
    lgas: [
      "Anka",
      "Bakura",
      "Birnin Magaji/Kiyaw",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Isa",
      "Kaura-Namoda",
      "Kiyawa",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata-Mafara",
      "Tsafe",
      "Zurmi",
    ],
  },
];

export const nigerianStates = nigerian.map((item) => {
  return { value: item?.state, label: item?.state };
});

export const stateOptions = [
  // { value: "", label: "Choose state", icon: "" },
  { value: "AB", label: "Abia" },
  { value: "AD", label: "Adamawa" },
  { value: "AK", label: "Akwa Ibom" },
  { value: "AN", label: "Anambra" },
  { value: "BA", label: "Bauchi" },
  { value: "BE", label: "Benue" },
  { value: "BO", label: "Borno" },
  { value: "CR", label: "Cross River" },
  { value: "DE", label: "Delta" },
  { value: "EB", label: "Ebonyi" },
  { value: "ED", label: "Edo" },
  { value: "EK", label: "Ekiti" },
  { value: "EN", label: "Enugu" },
  { value: "FC", label: "Federal Capital Territory" },
  { value: "GO", label: "Gombe" },
  { value: "IM", label: "Imo" },
  { value: "JI", label: "Jigawa" },
  { value: "KD", label: "Kaduna" },
  { value: "KN", label: "Kano" },
  { value: "KT", label: "Katsina" },
  { value: "KE", label: "Kebbi" },
  { value: "KO", label: "Kogi" },
  { value: "KW", label: "Kwara" },
  { value: "LA", label: "Lagos" },
  { value: "NA", label: "Nasarawa" },
  { value: "NI", label: "Niger" },
  { value: "OG", label: "Ogun" },
  { value: "ON", label: "Ondo" },
  { value: "OS", label: "Osun" },
  { value: "OY", label: "Oyo" },
  { value: "PL", label: "Plateau" },
  { value: "RI", label: "Rivers" },
  { value: "SO", label: "Sokoto" },
  { value: "TA", label: "Taraba" },
  { value: "YO", label: "Yobe" },
  { value: "ZA", label: "Zamfara" },
];

export const formatArray = (array, objKey) => {
  return array.map((item) => {
    return objKey
      ? { ...item, value: item[objKey], label: item[objKey] }
      : { value: item, label: item };
  });
};

export const banks = formatArray([
  "Guaranty Trust Bank Plc",
  "First Bank",
  "Access Bank Plc",
  "Fidelity Bank Plc",
  "First City Monument Bank Limited",
  "Union Bank of Nigeria Plc",
  "United Bank for Africa Plc",
  "Zenith Bank Plc",
  "Citibank Nigeria Limited",
  "Ecobank Nigeria Plc",
  "Heritage Bank Plc",
  "Keystone Bank Limited",
  "Optimus Bank Limited",
  "Polaris Bank Limited",
  "Stanbic IBTC Bank Plc",
  "Standard Chartered",
  "Sterling Bank Plc",
  "Titan Trust bank",
  "Unity Bank Plc",
  "Wema Bank Plc",
]);

export const idTypesOptions = [
  { value: "national_identity_card", label: "National Identity Card" },
  { value: "international_passport", label: "International Passport" },
  { value: "driver_license", label: "Driver's License" },
  { value: "permanent_voter_card", label: "Permanent Voter's Card (PVC)" },
  {
    value: "national_identification_number",
    label: "National Identification Number (NIN)",
  },
];
export const referrals = [
  { value: "Referred by a member", label: "Referred by a member" },
  { value: "LinkedIn", label: "LinkedIn" },
  { value: "Facebook", label: "Facebook" },
  { value: "Instagram", label: "Instagram" },
  {
    value: "Others",
    label: "Others",
  },
];

export const zipcodeOptions = [
  // { value: "", label: "Select code", icon: "" },
  { value: "12345", label: "12345" },
  { value: "67890", label: "67890" },
  { value: "54321", label: "54321" },
];

export const upcomingDetails = [
  {
    id: 1,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 2,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  // {
  //   id: 3,
  //   company: "Pernacle Limited",
  //   dueDate: "06/2024",
  //   price: 244.04,
  // },
  // {
  //   id: 5,
  //   company: "Pernacle Limited",
  //   dueDate: "06/2024",
  //   price: 244.04,
  // },
  // {
  //   id: 5,
  //   company: "Pernacle Limited",
  //   dueDate: "06/2024",
  //   price: 244.04,
  // },
  // {
  //   id: 6,
  //   company: "Pernacle Limited",
  //   dueDate: "06/2024",
  //   price: 244.04,
  // },
  // {
  //   id: 7,
  //   company: "Pernacle Limited",
  //   dueDate: "06/2024",
  //   price: 244.04,
  // },
  // {
  //   id: 8,
  //   company: "Pernacle Limited",
  //   dueDate: "06/2024",
  //   price: 244.04,
  // },
  // {
  //   id: 9,
  //   company: "Pernacle Limited",
  //   dueDate: "06/2024",
  //   price: 244.04,
  // },
  // {
  //   id: 10,
  //   company: "Pernacle Limited",
  //   dueDate: "06/2024",
  //   price: 244.04,
  // },
  // {
  //   id: 11,
  //   company: "Pernacle Limited",
  //   dueDate: "06/2024",
  //   price: 244.04,
  // },
  // {
  //   id: 12,
  //   company: "Pernacle Limited",
  //   dueDate: "06/2024",
  //   price: 244.04,
  // },
];

export const currencyOptions = [
  // { value: "", label: "Choose Currency", icon: "" },
  { value: "NGR", label: "Naira (₦)" },
  { value: "USD", label: "USD ($)" },
];
export const statmentTypes = [
  { value: "Account Statement", label: "Account Statement" },
  { value: "Investment Statement", label: "Investment Statement" },
];

export const genderOptions = [
  // { value: "", label: "Choose Gender", icon: "" },
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];
export const nationality = [{ value: "nigerian", label: "Nigerian" }];
export const relationshipOptions = [
  // { value: "", label: "Choose relation status", icon: "" },
  { value: "Spouse", label: "Spouse" },
  { value: "Sibling", label: "Sibling" },
  { value: "Son", label: "Son" },
  { value: "Daughter", label: "Daughter" },
  { value: "Parent", label: "Parent" },
];

export const phoneOptions = [
  { value: "NG", label: "+234", icon: "/Assets/NG.png" },
];

export const investmentOptions = [
  { value: "investment_statement", label: "Investment Statement" },
];

export const countries = formatArray([
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua &amp; Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia &amp; Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre &amp; Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts &amp; Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad &amp; Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks &amp; Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
]);

export const nationalities = formatArray(
  [
    { NationalityID: 1, CountryCode: "GB", Nationality: "British" },
    { NationalityID: 34, CountryCode: "AF", Nationality: "Afghan" },
    { NationalityID: 35, CountryCode: "AL", Nationality: "Albanian" },
    { NationalityID: 36, CountryCode: "DZ", Nationality: "Algerian" },
    { NationalityID: 158, CountryCode: "US", Nationality: "American" },
    { NationalityID: 38, CountryCode: "AD", Nationality: "Andorran" },
    { NationalityID: 39, CountryCode: "AO", Nationality: "Angolan" },
    { NationalityID: 40, CountryCode: "AM", Nationality: "Armenian" },
    { NationalityID: 41, CountryCode: "AT", Nationality: "Austrian" },
    { NationalityID: 42, CountryCode: "AZ", Nationality: "Azerbaijani" },
    { NationalityID: 2, CountryCode: "AR", Nationality: "Argentinian" },
    { NationalityID: 3, CountryCode: "AU", Nationality: "Australian" },
    { NationalityID: 43, CountryCode: "BH", Nationality: "Bahraini" },
    { NationalityID: 44, CountryCode: "BD", Nationality: "Bangladeshi" },
    { NationalityID: 45, CountryCode: "BB", Nationality: "Barbadian" },
    { NationalityID: 46, CountryCode: "BY", Nationality: "Belarusian" },
    { NationalityID: 47, CountryCode: "BZ", Nationality: "Belizean" },
    { NationalityID: 48, CountryCode: "BJ", Nationality: "Beninese" },
    { NationalityID: 49, CountryCode: "BM", Nationality: "Bermudian" },
    { NationalityID: 50, CountryCode: "BT", Nationality: "Bhutanese" },
    { NationalityID: 51, CountryCode: "BO", Nationality: "Bolivian" },
    { NationalityID: 52, CountryCode: "BA", Nationality: "Bosnian" },

    { NationalityID: 53, CountryCode: "BW", Nationality: "Botswanan" },
    { NationalityID: 54, CountryCode: "BG", Nationality: "Bulgarian" },
    { NationalityID: 55, CountryCode: "BF", Nationality: "Burkinese" },
    { NationalityID: 56, CountryCode: "BI", Nationality: "Burundian" },
    { NationalityID: 7, CountryCode: "CA", Nationality: "Canadian" },
    { NationalityID: 8, CountryCode: "CN", Nationality: "Chinese" },
    { NationalityID: 9, CountryCode: "CO", Nationality: "Colombian" },
    { NationalityID: 10, CountryCode: "CU", Nationality: "Cuban" },
    { NationalityID: 57, CountryCode: "KH", Nationality: "Cambodian" },
    { NationalityID: 58, CountryCode: "CM", Nationality: "Cameroonian" },
    { NationalityID: 59, CountryCode: "CV", Nationality: "Cape Verdean" },
    { NationalityID: 60, CountryCode: "TD", Nationality: "Chadian" },
    { NationalityID: 61, CountryCode: "CL", Nationality: "Chilean" },
    { NationalityID: 62, CountryCode: "CG", Nationality: "Congolese" },
    { NationalityID: 63, CountryCode: "CR", Nationality: "Costa Rican" },
    { NationalityID: 64, CountryCode: "HR", Nationality: "Croat" },
    { NationalityID: 65, CountryCode: "CY", Nationality: "Cypriot" },
    { NationalityID: 66, CountryCode: "CZ", Nationality: "Czech" },
    { NationalityID: 67, CountryCode: "DK", Nationality: "Danish" },
    { NationalityID: 11, CountryCode: "DO", Nationality: "Dominican" },
    { NationalityID: 68, CountryCode: "DJ", Nationality: "Djiboutian" },
    { NationalityID: 69, CountryCode: "DM", Nationality: "Dominican" },
    { NationalityID: 26, CountryCode: "NL", Nationality: "Dutch" },
    { NationalityID: 12, CountryCode: "EC", Nationality: "Ecuadorean" },
    { NationalityID: 70, CountryCode: "EG", Nationality: "Egyptian" },
    { NationalityID: 71, CountryCode: "ER", Nationality: "Eritrean" },
    { NationalityID: 72, CountryCode: "EE", Nationality: "Estonian" },
    { NationalityID: 73, CountryCode: "ET", Nationality: "Ethiopian" },
    { NationalityID: 74, CountryCode: "FJ", Nationality: "Fijian" },
    { NationalityID: 75, CountryCode: "FI", Nationality: "Finnish" },
    { NationalityID: 76, CountryCode: "PF", Nationality: "French Polynesian" },
    { NationalityID: 14, CountryCode: "FR", Nationality: "French" },
    { NationalityID: 77, CountryCode: "GA", Nationality: "Gabonese" },
    { NationalityID: 78, CountryCode: "GM", Nationality: "Gambian" },
    { NationalityID: 79, CountryCode: "GE", Nationality: "Georgian" },
    { NationalityID: 15, CountryCode: "DE", Nationality: "German" },
    { NationalityID: 16, CountryCode: "GT", Nationality: "Guatemalan" },
    { NationalityID: 80, CountryCode: "GH", Nationality: "Ghanaian" },
    { NationalityID: 81, CountryCode: "GR", Nationality: "Greek" },
    { NationalityID: 82, CountryCode: "GD", Nationality: "Grenadian" },
    { NationalityID: 83, CountryCode: "GN", Nationality: "Guinean" },
    { NationalityID: 84, CountryCode: "GY", Nationality: "Guyanese" },
    { NationalityID: 17, CountryCode: "HT", Nationality: "Haitian" },
    { NationalityID: 18, CountryCode: "HN", Nationality: "Honduran" },
    { NationalityID: 85, CountryCode: "HU", Nationality: "Hungarian" },
    { NationalityID: 19, CountryCode: "IN", Nationality: "Indian" },
    { NationalityID: 20, CountryCode: "IE", Nationality: "Ireland" },
    { NationalityID: 21, CountryCode: "IL", Nationality: "Israeli" },
    { NationalityID: 22, CountryCode: "IT", Nationality: "Italian" },
    { NationalityID: 86, CountryCode: "IS", Nationality: "Icelandic" },
    { NationalityID: 87, CountryCode: "ID", Nationality: "Indonesian" },
    { NationalityID: 88, CountryCode: "IR", Nationality: "Iranian" },
    { NationalityID: 89, CountryCode: "IQ", Nationality: "Iraqi" },
    { NationalityID: 23, CountryCode: "JP", Nationality: "Japanese" },
    { NationalityID: 90, CountryCode: "JM", Nationality: "Jamaican" },
    { NationalityID: 91, CountryCode: "JO", Nationality: "Jordanian" },
    { NationalityID: 92, CountryCode: "KZ", Nationality: "Kazakh" },
    { NationalityID: 93, CountryCode: "KE", Nationality: "Kenyan" },

    { NationalityID: 94, CountryCode: "KP", Nationality: "North Korean" },
    { NationalityID: 95, CountryCode: "KW", Nationality: "Kuwaiti" },
    { NationalityID: 96, CountryCode: "LV", Nationality: "Latvian" },
    { NationalityID: 97, CountryCode: "LB", Nationality: "Lebanese" },
    { NationalityID: 98, CountryCode: "LR", Nationality: "Liberian" },
    { NationalityID: 99, CountryCode: "LY", Nationality: "Libyan" },
    { NationalityID: 100, CountryCode: "LT", Nationality: "Lithuanian" },
    { NationalityID: 101, CountryCode: "LU", Nationality: "LUXEMBOURG" },
    { NationalityID: 102, CountryCode: "MG", Nationality: "Madagascan" },
    { NationalityID: 103, CountryCode: "MW", Nationality: "Malawian" },
    { NationalityID: 104, CountryCode: "MY", Nationality: "Malaysian" },
    { NationalityID: 105, CountryCode: "MV", Nationality: "Maldivian" },
    { NationalityID: 106, CountryCode: "ML", Nationality: "Malian" },
    { NationalityID: 107, CountryCode: "MT", Nationality: "Maltese" },
    { NationalityID: 108, CountryCode: "MR", Nationality: "Mauritanian" },
    { NationalityID: 109, CountryCode: "MU", Nationality: "Mauritian" },
    { NationalityID: 110, CountryCode: "MC", Nationality: "Monacan" },
    { NationalityID: 111, CountryCode: "MN", Nationality: "Mongolian" },
    { NationalityID: 112, CountryCode: "ME", Nationality: "Montenegrin" },
    { NationalityID: 113, CountryCode: "MA", Nationality: "Moroccan" },
    { NationalityID: 114, CountryCode: "MZ", Nationality: "Mozambican" },
    { NationalityID: 25, CountryCode: "MX", Nationality: "Mexican" },
    { NationalityID: 115, CountryCode: "NA", Nationality: "Namibian" },
    { NationalityID: 116, CountryCode: "NP", Nationality: "Nepalese" },
    { NationalityID: 117, CountryCode: "NZ", Nationality: "New Zealand" },
    { NationalityID: 118, CountryCode: "NI", Nationality: "Nicaraguan" },
    { NationalityID: 119, CountryCode: "NE", Nationality: "Nigerien" },
    { NationalityID: 120, CountryCode: "NG", Nationality: "Nigerian" },
    { NationalityID: 121, CountryCode: "NO", Nationality: "Norwegian" },
    { NationalityID: 122, CountryCode: "OM", Nationality: "Omani" },
    { NationalityID: 123, CountryCode: "PK", Nationality: "Pakistani" },
    { NationalityID: 124, CountryCode: "PA", Nationality: "Panamanian" },
    { NationalityID: 125, CountryCode: "PG", Nationality: "Guinean" },
    { NationalityID: 126, CountryCode: "PY", Nationality: "Paraguayan" },
    { NationalityID: 127, CountryCode: "PE", Nationality: "Peruvian" },
    { NationalityID: 27, CountryCode: "PH", Nationality: "Philippine" },
    { NationalityID: 128, CountryCode: "PL", Nationality: "Polish" },
    { NationalityID: 129, CountryCode: "PT", Nationality: "Portuguese" },
    { NationalityID: 130, CountryCode: "QA", Nationality: "Qatari" },
    { NationalityID: 131, CountryCode: "RO", Nationality: "Romanian" },
    { NationalityID: 132, CountryCode: "RW", Nationality: "Rwandan" },
    { NationalityID: 13, CountryCode: "SV", Nationality: "Salvadorean" },
    { NationalityID: 37, CountryCode: "AS", Nationality: "Samoan" },
    { NationalityID: 133, CountryCode: "SA", Nationality: "Saudi Arabian" },
    { NationalityID: 134, CountryCode: "SN", Nationality: "Senegalese" },
    { NationalityID: 135, CountryCode: "RS", Nationality: "Serbian" },
    { NationalityID: 136, CountryCode: "SL", Nationality: "Sierra Leonian" },
    { NationalityID: 137, CountryCode: "SG", Nationality: "Singaporean" },
    { NationalityID: 138, CountryCode: "SK", Nationality: "Slovak" },
    { NationalityID: 139, CountryCode: "SI", Nationality: "Slovenian" },
    { NationalityID: 140, CountryCode: "SB", Nationality: "Slomoni" },
    { NationalityID: 141, CountryCode: "SO", Nationality: "Somali" },
    { NationalityID: 142, CountryCode: "ZA", Nationality: "South African" },
    { NationalityID: 24, CountryCode: "KR", Nationality: "South Korean" },
    { NationalityID: 28, CountryCode: "ES", Nationality: "Spanish" },
    { NationalityID: 29, CountryCode: "SE", Nationality: "Swedish" },
    { NationalityID: 30, CountryCode: "CH", Nationality: "Swiss" },
    { NationalityID: 143, CountryCode: "LK", Nationality: "Sri Lankan" },
    { NationalityID: 144, CountryCode: "SD", Nationality: "Sudanese" },
    { NationalityID: 145, CountryCode: "SR", Nationality: "Surinamese" },
    { NationalityID: 146, CountryCode: "SZ", Nationality: "Swazi" },
    { NationalityID: 31, CountryCode: "TW", Nationality: "Taiwanese" },
    { NationalityID: 147, CountryCode: "TJ", Nationality: "Tajik" },
    { NationalityID: 148, CountryCode: "TH", Nationality: "Thai" },
    { NationalityID: 149, CountryCode: "TG", Nationality: "Togolese" },
    { NationalityID: 150, CountryCode: "TT", Nationality: "Trinidadian" },
    { NationalityID: 151, CountryCode: "TN", Nationality: "Tunisian" },
    { NationalityID: 152, CountryCode: "TR", Nationality: "Turkish" },
    { NationalityID: 153, CountryCode: "TM", Nationality: "Turkoman" },
    { NationalityID: 154, CountryCode: "TV", Nationality: "Tuvaluan" },
    { NationalityID: 155, CountryCode: "UG", Nationality: "Ugandan" },
    { NationalityID: 156, CountryCode: "UA", Nationality: "Ukrainian" },
    { NationalityID: 157, CountryCode: "AE", Nationality: "Emirati" },
    { NationalityID: 32, CountryCode: "VE", Nationality: "Venezuelan" },
    { NationalityID: 33, CountryCode: "VN", Nationality: "Vietnamese" },
    { NationalityID: 159, CountryCode: "UY", Nationality: "Uruguayan" },
    { NationalityID: 160, CountryCode: "UZ", Nationality: "Uzbek" },
    { NationalityID: 161, CountryCode: "VU", Nationality: "Vanuatuan" },
    { NationalityID: 162, CountryCode: "YE", Nationality: "Yemeni" },
    { NationalityID: 163, CountryCode: "ZM", Nationality: "Zambian" },
  ],
  "Nationality"
);

export const statuses = {
  Paid: {
    textColor: "#067647",
    borderColor: "#ABEFC6",
    backgroundColor: "#ECFDF3",
  },
  "Not Paid": {
    textColor: "#B42318",
    borderColor: "#FECDCA",
    backgroundColor: "#FEF3F2",
  },
  pending: {
    textColor: "#B42318",
    borderColor: "#FECDCA",
    backgroundColor: "#FEF3F2",
  },
  success: {
    textColor: "#067647",
    borderColor: "#ABEFC6",
    backgroundColor: "#ECFDF3",
  },
};

export const steps = [
  {
    id: 1,
    label: "Personal Details",
    link: "/onboarding",
  },
  {
    id: 2,
    label: "Investor Profile",
    link: "/onboarding/investor-profile",
  },

  {
    id: 3,
    label: "Membership Plan",
    link: "/onboarding/membership",
  },
];

export const incomeSources = ["Salary", "Business Income", "Investment Income"];
export const incomeRanges = [
  "$50,000 and above",
  "$20,000  - $50,000",
  "$5,000 - $20,000",
  "Less than $5,000",
];
export const categories = [
  "SME Financing",
  "Real Estate Investing",
  "Startup Investing",
  "Exchange Traded Commodities",
];
export const goals = [
  "Capital preservation",
  "Income generation",
  "Capital growth",
  "Others (please specify)",
];
export const ristTolerance = [
  "Very Conservative",
  "Conservative",
  "Moderately Conservative",
  "Balanced",
  "Moderately Aggressive",
  "Aggressive",
  "Very Aggressive",
  "I'm unsure",
];
export const hasPastInvestments = ["Yes", "No"];

export const usdAccOptions = [
  {
    method: "Transfers from Nigeria",
    details: null,
    subType: "reach-out",

    // details: {
    //   "Account Name": "Yebitrad Trading Enterprise",

    //   "Account Number": "0728305037",

    //   Currency: "USD",

    //   Bank: "GTBank Plc",
    //   "Swift Code": "GTBINGLA",

    //   "Bank Address": "Plot 635, Akin Adesola Victoria Island, Lagos",

    //   "Beneficiary Address":
    //     "Shop 15, Art & Craft Centre, Eko Hotel and Suite, Adetokunbo Ademola Street, Victoria Island, Lagos",
    // },
    note: "",
  },
  // {
  //   method: "Cash Deposits (Only from Nigeria)",
  //   details: null,
  //   note: "Please send a WhatsApp message to Ridwan at +234 802 909 3009.",
  // },
  {
    method: "Transfers from the US",
    details: {
      "Account Name": "Halcredit Technologies Ltd",

      "Account Number": "8810496364",

      "ABA Routing Code": "026073150",

      "Bank Name": "Community Federal Savings Bank",

      "Bank Address": "8916 Jamaica Ave, Woodhaven, NY 11421, United States",

      Country: "United States",

      "Beneficiary Address":
        "No 12f Tola Adewunmi Street Maryland , Lagos, Nigeria",
    },
  },
  {
    method: "Transfers from Countries Other than the US and Nigeria",
    details: {
      "Account Name": "Halcredit Technologies Ltd",

      IBAN: "8810496364",

      "SWIFT Code": "CMFGUS33",

      "Bank Name": "Community Federal Savings Bank",

      "Bank Address": "8916 Jamaica Ave, Woodhaven, NY 11421, United States",

      Country: "United States",

      "Beneficiary Address":
        "No 12f Tola Adewunmi Street Maryland , Lagos, Nigeria",
    },
  },
];
