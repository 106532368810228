import React, { useEffect, useState } from "react";
import DoughnutChart from "./chart/DoughnutChart";
import Legend from "./Legend";
import useCurrency from "../../hooks/useCurrency";
import { useSelector } from "react-redux";

function PortfolioDetails() {
  const [_currency, getAmount] = useCurrency();

  const opportunitiesData = useSelector((state) => state.portfolio.portfolio);

  const [insightItems, setInsightItems] = useState([]);
  const Total_Investment = opportunitiesData?.reduce(
    (sum, item) => sum + +item?.amount,
    0
  );
  useEffect(() => {
    let mapped = [
      // {
      //   name: "SME Financing",
      //   amount: 0,
      //   value: (0 / Total_Investment) * 100,
      //   color: "#7A5AF8",
      // },
      // {
      //   name: "Real Estate",
      //   amount: 0,
      //   value: (0 / Total_Investment) * 100,
      //   color: "#C11574",
      // },
      // {
      //   name: "Startup Investing",
      //   amount: 0,
      //   value: (0 / Total_Investment) * 100,
      //   color: "#47CD89",
      // },
      // {
      //   name: "Others",
      //   amount: 0,
      //   value: (0 / Total_Investment) * 100,
      //   color: "#E04F16",
      // },
    ];
    opportunitiesData?.forEach((item) => {
      if (
        item?.investment_category?.name !== "SME Financing" &&
        item?.investment_category?.name !== "Real Estate" &&
        item?.investment_category?.name !== "Startup Investing" &&
        mapped.find((_item) => _item.name === "Others")
      ) {
        mapped = mapped.map((_item) => {
          const newValue = _item?.amount + +item?.amount;
          return _item.name === "Others"
            ? {
                ..._item,
                amount: newValue,
                value: (newValue / Total_Investment) * 100,
              }
            : _item;
        });
      } else if (
        item?.investment_category?.name !== "SME Financing" &&
        item?.investment_category?.name !== "Real Estate" &&
        item?.investment_category?.name !== "Startup Investing" &&
        !mapped.find((_item) => _item.name === "Others")
      ) {
        mapped.push({
          name: "Others",
          amount: +item?.amount,
          value: Number(((+item?.amount / Total_Investment) * 100).toFixed(2)),
          color: "#E04F16",
        });
      } else if (
        mapped.find((_item) => _item.name === item?.investment_category?.name)
      ) {
        mapped = mapped.map((_item) => {
          const newValue = _item?.amount + +item?.amount;
          return _item.name === item?.investment_category?.name
            ? {
                ..._item,
                amount: newValue,
                value: (newValue / Total_Investment) * 100,
              }
            : _item;
        });
      } else {
        mapped.push({
          name: item?.investment_category?.name,
          amount: +item?.amount,
          value: Number(((+item?.amount / Total_Investment) * 100).toFixed(2)),
          color:
            item?.investment_category?.name === "SME Financing"
              ? "#7A5AF8"
              : item?.investment_category?.name === "Real Estate"
              ? "#C11574"
              : item?.investment_category?.name === "Startup Investing"
              ? "#47CD89"
              : "E04F16",
        });
      }
    });

    setInsightItems(mapped);
  }, [opportunitiesData]);

  const COLORS = [
    "#7A5AF8", // Equity
    "#FEF0C7", // Transportation
    "#C11574", // Agriculture
    "#175CD3", // Real Estate
    "#47CD89", // Fixed Deposit
    "#E04F16", // Commodities
  ];

  return insightItems?.length < 1 ? (
    <></>
  ) : (
    <div className="">
      {/* <h1 className="font-[600] text-[18px] text-[#101828] ">My portfolio</h1> */}
      <div className="flex w-full gap-5 flex-wrap justify-center  lg:justify-start items-center">
        <div style={{ width: "200px", height: "280px" }}>
          <DoughnutChart
            getAmount={getAmount}
            myInvestments={opportunitiesData}
            _currency={_currency}
            insightItems={insightItems}
          />
        </div>

        <div className="min-h-[280px]">
          <Legend insightItems={insightItems} />
        </div>
      </div>
    </div>
  );
}

export default PortfolioDetails;
